import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Downshift from 'downshift'
import styles from './BonusCompass.module.css'
import Page from '../Layout/Page'
import Header from '../Layout/Header'
import Hero from '../Blocks/Hero'
import Content from '../Blocks/Content'
import List from '../Elements/List'
import ListItem from '../Elements/ListItem'

function BonusCompass(props) {
  const {
    allCasinoJson: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allCasinoJson {
          nodes {
            name
            license
            account
            url
            logo {
              src
              bg
            }
          }
        }
      }
    `,
  )
  const casinos = nodes && nodes.length > 0 ? nodes : []
  const [usedBonusSites, setUsedBonusSites] = React.useState([])
  const [casinoList, setCasinoList] = React.useState(casinos)

  React.useEffect(() => {
    if (usedBonusSites.length > 0) {
      setCasinoList(
        casinos.filter(casino => {
          let exists = false
          usedBonusSites.forEach(usedBonusSite => {
            if (casino.license === usedBonusSite.license) {
              exists = true
            }
          })
          return !exists
        }),
      )
    } else {
      setCasinoList(casinos)
    }
  }, [usedBonusSites])

  function removeFromUsedBonusSites(name) {
    setUsedBonusSites(
      usedBonusSites.filter(
        site => site.name.toLowerCase() !== name.toLowerCase(),
      ),
    )
  }

  function addToUsedBonusSites(item) {
    setUsedBonusSites([...usedBonusSites, item])
  }

  return (
    <React.Fragment>
      <Header>
        <Hero heading="Vill du hitta nya bonusar? – Testa Bonuskompassen">
          <p className={styles.preamble}>
            Bonuskompassen hjälper dig att hitta casinosidor där du kan få nya
            bonusar.
          </p>
        </Hero>
      </Header>
      <Page>
        <section className={styles.intro}>
          <p className={styles.introText}>
            När du anger namn på de casinosidor du redan har fått bonus på
            kommer bonuskompassen filtrera bort alla casinon med samma licens så
            att du i listan nedan bara ser de sidor där du fortfarande kan få
            nya bonusar.
          </p>
          <Content style={{ paddingTop: '0px' }}>
            <div className={styles.usedBonuses}>
              {usedBonusSites && usedBonusSites.length > 0 && (
                <React.Fragment>
                  {usedBonusSites.map((site, index) => (
                    <button
                      key={index}
                      onClick={() => removeFromUsedBonusSites(site.name)}
                      className={styles.usedBonus}
                      type="button"
                    >
                      {site.name}
                      <span className={styles.cross}>✕</span>
                    </button>
                  ))}
                </React.Fragment>
              )}
            </div>

            <Downshift
              onChange={item => addToUsedBonusSites(item)}
              itemToString={item => (item ? item.name : '')}
            >
              {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
              }) => (
                <div className={styles.downshift}>
                  <label className={styles.bonusLabel} {...getLabelProps()}>
                    Ange namn på casinosida
                  </label>
                  <div className={styles.bonusCompass}>
                    <input
                      className={styles.bonusInput}
                      placeholder="Namn på casinosida där du redan fått bonus"
                      type="text"
                      {...getInputProps({
                        autoCapitalize: 'off',
                      })}
                    />
                  </div>
                  <ul className={styles.autoList} {...getMenuProps()}>
                    {isOpen && inputValue.length > 2
                      ? casinos
                          .filter(
                            item =>
                              !inputValue ||
                              item.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()),
                          )
                          .map((item, index) => (
                            <li
                              {...getItemProps({
                                key: item.name,
                                index,
                                item,
                              })}
                              className={styles.autoListItem}
                            >
                              {item.name}
                            </li>
                          ))
                      : null}
                  </ul>
                </div>
              )}
            </Downshift>
          </Content>
        </section>
        <section className={styles.bonusList}>
          <Content>
            <List>
              {casinoList &&
                casinoList.length > 0 &&
                casinoList.map((casino, index) => (
                  <ListItem item={casino} key={index} />
                ))}
            </List>
          </Content>
        </section>
      </Page>
    </React.Fragment>
  )
}

export default BonusCompass
