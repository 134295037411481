import React from 'react'
import SEO from '../components/Blocks/SEO'
import BonusCompass from '../components/Pages/BonusCompass'

function BonusPage(props) {
  return (
    <React.Fragment>
      <SEO
        title="Hitta casinosidor med nya bonusar (2020)"
        description="Bonuskompassen är verktyget som hjälper dig att hitta nya bonusar"
      />
      <BonusCompass />
    </React.Fragment>
  )
}

export default BonusPage
